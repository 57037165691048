.cardDescription {
  @apply prose prose-indigo flex-1 bg-white p-6 flex flex-col justify-between;
}

.tag {
  composes: tag from "../pages/blog/blog.module.css";
}

.tag-green {
  composes: tag-green from "../pages/blog/blog.module.css";
}

.tag-blue {
  composes: tag-blue from "../pages/blog/blog.module.css";
}

.tag-yellow {
  composes: tag-yellow from "../pages/blog/blog.module.css";
}

.tag-gray {
  composes: tag-gray from "../pages/blog/blog.module.css";
}

.tag-indigo {
  composes: tag-indigo from "../pages/blog/blog.module.css";
}

.tag-pink {
  composes: tag-pink from "../pages/blog/blog.module.css";
}

.tag-red {
  composes: tag-red from "../pages/blog/blog.module.css";
}
