.link {
  @apply underline hover:text-gray-700;
}

.lightLink {
  @apply underline text-white hover:text-indigo-100;
}

.primaryLink {
  @apply underline text-color-primary hover:text-color-primary-hov;
}
.heroImage {
  @apply relative;
}

.heroImage .playDemoVideo {
  @apply sm:block md:hidden absolute top-0 bottom-0 left-0 right-0 m-auto inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full outline-none shadow-sm text-base font-medium text-white bg-color-primary hover:bg-color-primary-hov bg-opacity-70;
  width: 200px;
  height: 50px;
}

.heroImage:hover .playDemoVideo {
  @apply md:block;
}

/* Alternating features section */
.features {
  @apply relative py-16 overflow-hidden;
}

.features .feature {
  @apply relative;
}

.features .featureCols {
  @apply lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24;
}

.features .featureColTextLeft,
.features .featureColTextRight {
  @apply px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0;
}
.features .featureColTextRight {
  @apply lg:col-start-2;
}

.features .featureIcon {
  @apply h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-color-secondary to-color-primary;
}

.features .featureIcon svg {
  @apply h-6 w-6 text-white;
}

.features h2 {
  @apply text-3xl font-extrabold tracking-tight text-gray-900;
}
.features .featureDesc {
  @apply mt-4 text-lg text-gray-500;
}

.features .featureImageLeft,
.features .featureImageRight {
  @apply mt-4 sm:mt-16 lg:mt-0;
}

.features .featureImageLeft {
  @apply lg:col-start-1;
}

.features .imageWrapOuter {
  @apply lg:px-0 lg:m-0 lg:relative lg:h-full;
}
.features .featureImageLeft .imageWrapOuter {
  @apply pr-4 -ml-48 sm:pr-6 md:-ml-16;
}
.features .featureImageRight .imageWrapOuter {
  @apply pl-4 -mr-48 sm:pl-6 md:-mr-16;
}

.features .imageWrapInner {
  @apply text-center lg:absolute lg:top-0 lg:w-max lg:h-full;
}
.features .featureImageLeft .imageWrapInner {
  @apply lg:right-0;
}
.features .featureImageRight .imageWrapInner {
  @apply lg:left-0;
}
.features .imageWrapInner > div {
  @apply rounded-md shadow-xl ring-1 ring-black ring-opacity-5;
}

/* Case studies section */
.caseStudiesGrid {
  composes: blogPostsGrid from "./pages.module.css";
}

/* Panel features section */
span.regularGridIcon {
  @apply flex items-center justify-center h-12 w-12 rounded-md bg-color-primary bg-opacity-10;
}

span.regularGridIcon svg {
  @apply h-6 w-6 text-color-primary;
}

span.lightGridIcon {
  @apply flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10;
}

span.lightGridIcon svg {
  @apply h-6 w-6 text-white;
}

.gridText {
  @apply mt-6;
}

.gridText .label {
  @apply text-lg font-medium;
}

.gridText .lightLabel {
  @apply text-lg font-medium text-white;
}

.gridText .description {
  @apply mt-2 text-base;
}

.gridText .lightDescription {
  @apply mt-2 text-base text-violet-200;
}
